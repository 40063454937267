<div class="wrapper">
  <mat-chip-list #chipList aria-label="label">
    <mat-chip
      *ngFor="let selectedCheepsOption of selectedCheepsOptions$ | async; trackBy: tracById"
      class="mat-chip"
      [selectable]="true"
      [removable]="true"
      (removed)="onOptionToggle(selectedCheepsOption.id)"
    >
      {{ selectedCheepsOption.name }}
      <kp-svg matChipRemove key="x" color="#A1A4AD" size="16px"></kp-svg>
    </mat-chip>

    <mat-error *ngIf="(ngControl.touched || searchControl.touched) && ngControl.hasError(customError)">{{
        ngControl?.getError(customError)
      }}
    </mat-error>
  </mat-chip-list>

  <mat-form-field appearance="outline" class="chips-autocomplete-field no-focus"
    [class.mat-form-field-invalid]="(ngControl.touched || searchControl.touched) && ngControl.hasError(customError)"
  >
    <kp-svg key="searchR" color="#A1A4AD" size="24px"></kp-svg>
    <input
      matInput
      #trigger="matAutocompleteTrigger"
      [errorStateMatcher]="matcher"
      [placeholder]="placeholder"
      [formControl]="searchControl"
      [matAutocomplete]="auto"
      [matChipInputFor]="chipList"
      (keyup)="onSearch()"
      (click)="trigger.openPanel()"
    />

    <mat-autocomplete
      #auto="matAutocomplete"
      (optionSelected)="onOptionToggle($event.option.value)"
      panelClass="mat-slt-panel"
    >
      <mat-option *ngFor="let option of options$ | async" [value]="option.id">
        {{ option.name }}
      </mat-option>
    </mat-autocomplete>
  </mat-form-field>
</div>

