import {
  ChangeDetectorRef,
  Component,
  Inject,
  InjectionToken,
  Input,
  OnInit,
  Optional,
  Provider,
  Self,
  Type,
} from '@angular/core';
import { ControlValueAccessor, FormControl, NgControl } from '@angular/forms';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { MyErrorStateMatcher } from '@shared/utils/value-accesser-error-matcher';
import { uniqBy } from 'lodash';
import { BehaviorSubject, combineLatest, Observable, Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged, filter, map, switchMap, tap } from 'rxjs/operators';
import { customError } from '../../utils/custom-error';
import { trackById } from '../../utils/track-by/track-by-id.function';

export type ID = number | string;

export interface CheepsOptions {
  id: ID;
  name: string;
}

export interface FetchCheepsOptions {
  fetchCheepsOptions(query?: string): Observable<CheepsOptions[]>;
}

export const FETCH_CHEEPS_OPTIONS = new InjectionToken<FetchCheepsOptions>('FETCH_CHEEPS_OPTIONS');

export function fetchCheepsOptionsProvider(service: Type<FetchCheepsOptions>): Provider {
  return { provide: FETCH_CHEEPS_OPTIONS, useClass: service };
}

@UntilDestroy()
@Component({
  selector: 'kp-chips-autocomplete',
  templateUrl: './chips-autocomplete.component.html',
  styleUrls: ['./chips-autocomplete.component.scss'],
})
export class ChipsAutocompleteComponent implements ControlValueAccessor, OnInit {
  @Input() public placeholder: string;
  @Input() public prepopulatedOptions: CheepsOptions[] = [];

  public searchControl = new FormControl();
  public options$ = new Subject<CheepsOptions[]>();
  public searchQuery$ = new BehaviorSubject<string>('');
  public selectedCheepsOptionsIds$ = new BehaviorSubject<ID[]>([]);

  public selectedCheepsOptions$: Observable<CheepsOptions[]> = combineLatest([
    this.options$,
    this.selectedCheepsOptionsIds$,
  ]).pipe(
    untilDestroyed(this),
    map(([options, ids]) => {
      const optionsDictionary = options.reduce((acc, option) => {
        acc[option.id] = option;
        return acc;
      }, {} as Record<ID, CheepsOptions>);

      return ids.map((id) => optionsDictionary[id]);
    }),
    tap((selectedOptions) => (this.prepopulatedOptions = selectedOptions)),
  );

  public customError = customError;
  public matcher = new MyErrorStateMatcher(this.ngControl);
  public tracById = trackById;

  constructor(
    @Optional() @Self() public ngControl: NgControl,
    @Inject(FETCH_CHEEPS_OPTIONS) private fetchCheepsOptions: FetchCheepsOptions,
    private cdr: ChangeDetectorRef,
  ) {
    if (Boolean(this.ngControl)) {
      this.ngControl.valueAccessor = this;
    }

    this.searchQuery$
      .pipe(
        distinctUntilChanged(),
        debounceTime(500),
        untilDestroyed(this),
        switchMap((query) => this.fetchCheepsOptions.fetchCheepsOptions(query ?? '')),
      )
      .subscribe((options) => {
        this.options$.next(uniqBy([...this.prepopulatedOptions, ...options], 'id'));
      });
  }

  public onSearch() {
    this.searchQuery$.next(this.searchControl.value);
  }

  public ngOnInit(): void {
    this.ngControl?.statusChanges?.pipe(untilDestroyed(this)).subscribe(() => {
      this.cdr.markForCheck();
    });
  }

  public onChange: (ids: ID[]) => void = () => null;
  public onTouched = (): null => null;

  public writeValue(ids: ID[]): void {
    this.selectedCheepsOptionsIds$.next(ids ?? []);
  }

  public registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  public registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  public onOptionToggle(optionId: ID) {
    let ids = [...this.selectedCheepsOptionsIds$.value];
    const optionIndex = ids.findIndex((id) => optionId === id);

    if (optionIndex === -1) {
      ids.push(optionId);
    } else {
      ids.splice(optionIndex, 1);
    }

    this.selectedCheepsOptionsIds$.next(ids);
    this.onChange(ids);

    this.searchControl.setValue('');
    this.onSearch();
  }
}
