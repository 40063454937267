import { Injectable } from '@angular/core';
import { from, Observable, of } from 'rxjs';
import { catchError, map, mergeMap, toArray } from 'rxjs/operators';
import { IEventDto } from '@shared/interfaces/admin/event-interface';
import { IChatDto } from '@shared/models/messages/dto/chat-dto.model';
import { IUserWithProfileDto } from '@shared/models/user/dto/user-dto.model';
import { ChatHttpService } from '@core/services/api/messages/chat-http.service';
import { EventHttpService } from '@core/services/api/events/event-http.service';
import { MembersUserHttpService } from '@core/services/api/members/members-user-http.service';
import { NewslinePostHttpService } from '@core/services/api/newsline/newsline-post-http.service';
import { INewslinePostNew, INewslineStory } from '@shared/models/newsline/dto/newsline-post-dto.model';

enum LinksDefinderEnum {
  Profile = 'profile',
  Messages = 'messages',
  Events = 'event',
  Post = 'post',
  Story = 'story',
  External = 'external'
}

interface ILinkMetaInfo {
  image?: string;
  subtitle?: string;
  title?: string;
  type?: string;
}

@Injectable({
  providedIn: 'root',
})
export class ChatLinkMetaInfoScrapingService {
  constructor(
    private chatHttpService: ChatHttpService,
    private eventHttpService: EventHttpService,
    private membersUserHttpService: MembersUserHttpService,
    private newslinePostHttpService: NewslinePostHttpService,
  ) {}

  public scrapLinkMetaInfo(links: string[]): Observable<any> {
    return from(links).pipe(
      mergeMap((link: string) => {
        return this.getMetaInfo(link).pipe(
          map(meta_info => ({
            link,
            meta_info
          }))
        );
      }),
      toArray()
    );
  }

  private getMetaInfo(link: string): Observable<ILinkMetaInfo> {
    if (link.includes(LinksDefinderEnum.Profile)) {
      return this._getProfileMetaInfo(link);
    } else if (link.includes(LinksDefinderEnum.Messages)) {
      return this._getChatMetaInfo(link);
    } else if (link.includes(LinksDefinderEnum.Events)) {
      return this._getEventMetaInfo(link);
    } else if (link.includes(LinksDefinderEnum.Post)) {
      return this._getNewsLineMetaInfo(link);
    } else if (link.includes(LinksDefinderEnum.Story)) {
      return this._getStoryMetaInfo(link);
    } else {
      return this._getOtherWebSiteMetaInfo(link);
    }
  }

  // Пример возвращаемых данных из методов получения метаинформации
  private _getProfileMetaInfo(link: string): Observable<ILinkMetaInfo> {
    const segments: string[] = link.split('/');
    const userId: number = +segments.pop();

    return this.membersUserHttpService.getUserById(userId).pipe(mergeMap((res: IUserWithProfileDto) => {
      return of(
        {
          image: res.avatar,
          title: res.first_name + ' ' + res.last_name,
          subtitle: res.can_be_useful,
          type: LinksDefinderEnum.Profile.toUpperCase()
        }
      );
    }))
  }

  private _getChatMetaInfo(link: string): Observable<ILinkMetaInfo> {
    const segments: string[] = link.split('/');
    const chatId: number = +segments.pop();

    return this.chatHttpService.getById(chatId).pipe(mergeMap((res: IChatDto) => {
      return of(
        {
          image: res.avatar,
          title: res.name,
          subtitle: res.description,
          type: 'CHAT'
        }
      );
    }))
  }

  private _getEventMetaInfo(link: string): Observable<ILinkMetaInfo> {
    const segments: string[] = link.split('/');
    const eventId: number = +segments.pop();

    return this.eventHttpService.getEventById(eventId).pipe(mergeMap((event: IEventDto) => {
      return of(
        {
          image: event.avatar,
          title: event.name,
          subtitle: event.description,
          type: LinksDefinderEnum.Events.toUpperCase()
        }
      );
    }))
  }

  private _getNewsLineMetaInfo(link: string): Observable<ILinkMetaInfo> {
    const segments: string[] = link.split('/');
    const postId: number = +segments.pop();

    return this.newslinePostHttpService.getPostByIdNew(postId).pipe(mergeMap((post: INewslinePostNew) => {
      return of(
        {
          image: post.files[0]?.file,
          title: post.title,
          subtitle: post.text,
          post_author_info: {
            author: {
              avatar: post.author.avatar,
              first_name: post.author.first_name,
              id: post.author.id,
              last_name: post.author.last_name,
              sex: post.author.sex,
              username: post.author.username
            },
            created: post.created,
          },
          type: LinksDefinderEnum.Post.toUpperCase()
        }
      );
    }))
  }

  private _getStoryMetaInfo(link: string): Observable<ILinkMetaInfo> {
    const segments: string[] = link.split('/');
    const storyId: number = +segments.pop();

    return this.newslinePostHttpService.getStoryById(storyId).pipe(mergeMap((story: INewslineStory) => {
      return of(
        {
          image: story.file,
          title: `История от ${story.owner.first_name} ${story.owner.last_name} `,
          type: LinksDefinderEnum.Story.toUpperCase()
        }
      );
    }))
  }

  private _getOtherWebSiteMetaInfo(link: string): Observable<ILinkMetaInfo> {
    return this.newslinePostHttpService.getExternalLinkPreview(link).pipe(mergeMap((res: any) => {

      if (!res.description && !res.image && !res.title) {
        return of(null)
      }

      return of({
        image: res.image,
        subtitle: res.description,
        title: res.title,
      });
    }),
      catchError(() => {
        return of(null);
      }),
    )
  }
}
