import { HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { KeyValue } from '@shared/models/key-value.model';
import { INewslinePostVoteDto } from '@shared/models/newsline/dto/newsline-post-vote-dto.model';
import { INewslinePostVote } from '@shared/models/newsline/view/newsline-post-vote.model';
import { ArrayPayload } from '@shared/models/payload.model';
import { Observable } from 'rxjs';
import { HttpService } from '../http/http.service';
import { ChatPoll } from '@shared/models/messages/view/chat-poll.model';

@Injectable({
  providedIn: 'root',
})
export class NewslinePostVoteHttpService {
  private readonly _apiPath = '/newsline/poll/';

  constructor(private http: HttpService) {}

  public getNewslinePostPoll(chatPollId: number): Observable<any> {
    return this.http.get<ChatPoll>(`${this._apiPath}${chatPollId}`);
  }

  getVotes(params?: KeyValue<string>, paramsString?: string): Observable<ArrayPayload<INewslinePostVote>> {
    return this.http.get<ArrayPayload<INewslinePostVote>>(this._apiPath, {
      params: params ? new HttpParams({ fromObject: params }) : new HttpParams({ fromString: paramsString }),
    });
  }

  getVoteById(id: number): Observable<INewslinePostVote> {
    return this.http.get<INewslinePostVote>(`${this._apiPath}${id}/`);
  }

  createVote(vote: Partial<INewslinePostVote>): Observable<INewslinePostVote> {
    return this.http.post<INewslinePostVote>(this._apiPath, vote);
  }

  updateVoteById(id: number, vote: Partial<INewslinePostVote>): Observable<INewslinePostVote> {
    return this.http.patch<INewslinePostVote>(`${this._apiPath}${id}/`, vote);
  }

  deleteVoteById(id: number): Observable<void> {
    return this.http.delete<void>(`${this._apiPath}${id}/`);
  }

  getForumFirstMeetingVote(forumId: number) {
    return this.http.get<INewslinePostVoteDto>(`${this._apiPath}first_meeting_poll/?forum=${forumId}`);
  }
}
